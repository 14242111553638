const constant = {
    // REQ_WX_CONFIG: 'h5/wx/cfg/params',
    REQ_SIDEBAR_INFO_GET: '/h5/menu/info',
    REQ_APPKEY_INFO_GET: '/h5/merchant/get',
    // REQ_AUTH_LOGIN_VERIFY_SEND: '/h5/member/sendMobileCode',
    // REQ_AUTH_LOGIN_BY_VERIFY: '/h5/member/login',
    // REQ_WEIXIN_OPEN_ID_GET: 'weixin.get.openid',
    // REQ_WEIXIN_OPEN_ID_GET: '/h5/member/wx/openid/get',
    REQ_MEMBER_INFO_GET: '/h5/member/getMemberByAuthCode',
    REQ_MOVIE_CITY_LIST: "/h5/movie/city/list",
    REQ_MOVIE_COUNTY_LIST: '/h5/movie/county/list',
    REQ_ORDER_PAY: '/h5/movie/order/selectPayMethod',
    REQ_COUPON_GET: '/h5/coupon/get', // 不进系统获取卡券
    REQ_COUPON_BIND: '/h5/coupon/bind', // 不进系统绑定卡券

    REQ_AUTH_LOGIN_VERIFY_SEND: '/cv/merchant/user/login/code',
    REQ_AUTH_LOGIN_BY_VERIFY: '/cv/merchant/user/login',
    REQ_AUTH_USER_INFO: '/cv/merchant/user/info',
    REQ_AUTH_COUPON_VERIFY_USING: '/cv/coupon/verify/using',
    REQ_AUTH_COUPON_VERIFY_LIST: '/cv/coupon/verify/list',
    REQ_AUTH_COUPON_VERIFY_CANCEL: '/cv/coupon/verify/cancel',
    REQ_WX_CONFIG: '/cv/cfg/wx',
    REQ_AUTH_COUPON_INFO: '/cv/coupon/get',


    REQ_WEIXIN_OPEN_ID_GET: '/public/user/wxLogin',
};

export default constant;
