//项目主题样式
import style from '@/styles/project.scss';
//Key常量集合
import storageKey from './storage-key';

import store from '@/store';

const config = {
    const: storageKey,
    style, //全局样式
    remUnit: 37.5,
    requestTimeout: 1000 * 10, //默认请求超时时间
    defaultAppKey: "", //默认渠道appKey
    wxAppId: 'wx02c71114ad918b56',
    signKey: "",
    imgBaseUrl: "",
    defaultLocation: {
        cityCode: '510100',
        name: '成都',
    },
    defaultCounty: {
        name: '全城'
    },
    baseUrl: {
        dev: "",
        prod: "",
    },
    loadingMsg: '加载中',
    emptyPlaceholder: '　',
    getDefaultPagination: () => {
        return {
            current: 1,
            pages: 0,
            size: 10,
            total: 0,
        }
    }
};

export default Object.assign(config);
