import router from "@/router";
import store from "@/store";
import cookies from "vue-cookies";
import config from "@/config";
const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    var hasToken = cookies.get(config.const.KEY_AUTH_CODE);
    if(hasToken){
        if (to.path === '/login') {
            next({path: '/'})
        }else{
            try{
                await store.dispatch("getUserInfo");
                next()
            }catch (err){
                await store.dispatch('resetToken')
                next(`/login?redirect=${to.path}`)
            }
        }
    }else if(to.name.indexOf('error') != -1){
        next(`/404`)
    } else{
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
        }
    }

});
