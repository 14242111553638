import Vue from "vue";
import store from '@/store';
import VueRouter from "vue-router";

Vue.use(VueRouter);
export const routes = [
    {path: "/", redirect: "/pool"},
    {
        path: "/pool",
        name: "Pool",
        component: () => import('@/views/Ticket-Pool'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/wait",
        name: "Wait",
        component: () => import('@/views/Wait-Ticket'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/ticket",
        name: "Ticket",
        component: () => import('@/views/Ticket'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/Login'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/history",
        name: "history",
        component: () => import('@/views/History'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/mine",
        name: "Mine",
        component: () => import('@/views/Mine'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/order/detail",
        name: "OrderDetail",
        component: () => import('@/views/OrderDetail'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/cinema/rule",
        name: "CinemaRule",
        component: () => import('@/views/RuleDetail'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/withdraw",
        name: "Withdraw",
        component: () => import('@/views/Withdraw'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/404",
        name: "404",
        component: () => import('@/views/404'),
        meta: {
            showTab:false
        }
    },
    {
        path: "*",
        redirect: ()=>{
            return '404'
        },
        meta: {
            showTab:false
        }
    },
];

const createRouter = () => new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})

const router = createRouter()


// router.beforeEach((to, from, next) => {
//     // next();return;
//     console.log('beforeEach....')
//     if (to.meta.requireAuth && !store.getters.authCode) {
//         Toast('请先登录');
//         next({
//             path: '/login',
//             query: {
//                 redirect: to.fullPath
//             }
//         });
//     } else if (to.name != 'login' && to.name.indexOf('error') == -1 && store.getters.authCode) {
//         if (store.getters.hasGetMemberInfo) {
//             next();
//         } else {
//             store.dispatch('getBaseData');
//             store.dispatch('getMemberInfo').then(() => {
//                 next();
//             }).catch(() => {
//                 next();
//             })
//         }
//     } else {
//         next();
//     }
// });

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router;
